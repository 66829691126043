// Filename - App.js
 
import React from "react";
import {
    BrowserRouter as Router,
    Routes,
    Route,
} from "react-router-dom";
import Home from "./Pages/home";
import About from "./Pages/about";
import ProductPage from "./Pages/productPage";
import Lookbook from "./Pages/lookbook";
import Navbar from "./Components/Navbar";
import DeliveryAndReturns from "./Pages/deliveryAndReturns";

function App() {
  return (
      <Router>
      <Navbar/>
          <Routes>
              <Route exact path="/" element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/avery-hoodie" element={<ProductPage />} />
              <Route path="/lookbook" element={<Lookbook />} />
              <Route path="/delivery-and-returns" element={<DeliveryAndReturns />} />
          </Routes>
      </Router>
  );
}

export default App;