import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import BallPool from '../Components/BallPool';
import Modal from '../Components/Modal';
import styles from '../Styles/home.module.css'

console.log("Why are you poking around my code? Such a Lieu thing to do <3")

function HomePage() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [clickedBody, setClickedBody] = useState(null);
  const navigate = useNavigate();

  const hyperlinks = ["Oolong","Hibiscus","Sencha"]

  const handleBodyClick = (body) => {
    const specialIds = [5, 6, 7];
    if (specialIds.includes(body.id)) {
        var hyperlink_slug = hyperlinks[body.id-5]
        
      navigate(`/avery-hoodie?color=${hyperlink_slug}`); // Replace with your desired route
    } else {
        setClickedBody(body);
        setIsModalOpen(true);
    }
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setClickedBody(null); // Clear clicked body state on close
  };

  return (
    <div className = {styles.mainPage}>
    <BallPool className = {styles.matterjs} onBodyClick={handleBodyClick} />
    {isModalOpen && clickedBody && (
      <Modal onClose={handleModalClose} clickedBodyId={clickedBody.id}>
        
      </Modal>
    )}
  </div>
  );
}

export default HomePage;